









































































import { Observer } from 'mobx-vue'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Observer
@Component({
  components: {
    'rct-find-team-form': () => import('@/modules/recruitment/dialogs/forms/rct-find-team-form.vue'),
    'rct-find-hobby-form': () => import('@/modules/recruitment/dialogs/forms/rct-find-hobby-form.vue'),
    'rct-find-job': () => import('@/modules/recruitment/dialogs/forms/rct-find-job.vue'),
  },
})
export default class extends Vue {
  @Prop({ default: false }) notEditable
  @Prop({
    default: () => {
      return {}
    },
  })
  controller

  get heightInput() {
    return this.$vuetify.breakpoint.smOnly ? 44 : 50
  }
  get bgInput() {
    return this.$vuetify.breakpoint.smOnly ? 'primary darken-1' : 'primary darken-2'
  }
}
